@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("/static/fonts/Inter-Thin.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Thin.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("/static/fonts/Inter-ThinItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-ThinItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("/static/fonts/Inter-ExtraLight.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-ExtraLight.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("/static/fonts/Inter-ExtraLightItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-ExtraLightItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("/static/fonts/Inter-Light.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Light.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("/static/fonts/Inter-LightItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-LightItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("/static/fonts/Inter-Regular.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Regular.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("/static/fonts/Inter-Italic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Italic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("/static/fonts/Inter-Medium.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Medium.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("/static/fonts/Inter-MediumItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-MediumItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("/static/fonts/Inter-SemiBold.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-SemiBold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("/static/fonts/Inter-SemiBoldItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-SemiBoldItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/static/fonts/Inter-Bold.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Bold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("/static/fonts/Inter-BoldItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-BoldItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("/static/fonts/Inter-ExtraBold.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-ExtraBold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("/static/fonts/Inter-ExtraBoldItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-ExtraBoldItalic.woff?v=3.15") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("/static/fonts/Inter-Black.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-Black.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("/static/fonts/Inter-BlackItalic.woff2?v=3.15") format("woff2"),
       url("/static/fonts/Inter-BlackItalic.woff?v=3.15") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("/static/fonts/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("/static/fonts/Inter-italic.var.woff2") format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("/static/fonts/Inter.var.woff2?v=3.15") format("woff2");
}


body {
  color: white;
  background: rgb(20, 20, 20);
  margin: 0;
  padding: 0;
  overflow-anchor: none;
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  font-feature-settings: "cv05","cv06","cv07","cv08","cpsp","ss01","ss03","ss04";
  font-size: 16px;
  font-family: inter var, sans-serif;
  font-weight: 400;
  -webkit-letter-spacing: -0.010959778564167367em;
  -moz-letter-spacing: -0.010959778564167367em;
  -ms-letter-spacing: -0.010959778564167367em;
  letter-spacing: -0.010959778564167367em;
  line-height: 1.4;
}
:root{
    scrollbar-width: none !important
}


body::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

*::selection {
  background-color: white;
  color: black;
  text-shadow: none;
}

textarea:focus {
  text-shadow: none !important;
}

input[type="checkbox"] {
  filter: grayscale(100%) invert(80%) opacity(.65);
  cursor: pointer;
}

input[type="checkbox"]:checked {
  filter: grayscale(100%) invert(100%) brightness(130%) drop-shadow(0 0 1px rgba(0,0,0,1)) drop-shadow(0 0 3px rgba(0,0,0,.8));
}

input[type="radio"] {
  filter: grayscale(100%) invert(80%) opacity(.65);
  cursor: pointer;
}

input[type="radio"]:checked {
  filter: grayscale(100%) invert(100%) brightness(150%) drop-shadow(0 0 1px rgba(0,0,0,1)) drop-shadow(0 0 3px rgba(0,0,0,.8));
}